import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useBooleanFeatureFlag } from '@confluence/session-data';

import { LIVE_PAGES_PARENT_FF, LIVE_PAGES_FEATURE_GATE } from './featureFlags';

export const useIsLivePagesFeatureEnabled = (): boolean => {
	const isLivePagesEnabledLD = useBooleanFeatureFlag(LIVE_PAGES_PARENT_FF);
	const isLivePagesEnabledSS = FeatureGates.checkGate(LIVE_PAGES_FEATURE_GATE);
	return isLivePagesEnabledLD || isLivePagesEnabledSS;
};
